import React from "react";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";

const Navbar = () => {
	const { t } = useTranslation();

	return (
		<div className="navbar">
			<ul className="classic">
				<li>
					<Link to="about" smooth={true} duration={500}>
						About
					</Link>
				</li>
				<li>
					<Link to="projects" smooth={true} duration={500}>
						Projects
					</Link>
				</li>
				<li>
					<Link to="contact-section" smooth={true} duration={500}>
						Contact
					</Link>
				</li>
			</ul>
			<ul className="mobile">
				<li>
					<Link to="about" smooth={true} duration={500}>
						About
					</Link>
				</li>
				<li>
					<Link to="projects" smooth={true} duration={500}>
						Projects
					</Link>
				</li>
				<li>
					<Link to="contact-section" smooth={true} duration={500}>
						Contact
					</Link>
				</li>
			</ul>
		</div>
	);
};

export default Navbar;
