import React from "react";
import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

const Projects = () => {
	const projects = [
		{
			title: "Working @ ArianeGroup",
			screenshot: "./arianegroup.jpeg",
			link: "https://www.ariane.group/fr/",
			description:
				"Porting of a heavy data processing client application to the web",
			tech: ["C#", "ASP .NET Core", "C++", "JS"],
		},
		{
			title: "Prescrire Shop",
			screenshot: "./prescrire.png",
			link: "https://boutique.prescrire.org/boutique/fr_FR/",
			description:
				"An e-commerce website for Prescrire, a monthly medical journal",
			tech: ["PHP", "Symfony", "Doctrine ORM", "Twig"],
		},
		{
			title: "Unsplash",
			screenshot: "./my_unsplash.png",
			link: "https://fct-unsplash.netlify.app",
			description: "A clone of Unsplash, a website that provides free images",
			tech: ["React", "Node", "MongoDB"],
		},
		{
			title: "Sing Me A Comptine",
			screenshot: "./singmeacomptine.png",
			link: "https://singmeacomptine.com/",
			description:
				"A website collecting nursery rhymes for children in multiple languages",
			tech: ["PHP", "JS", "Solr", "MySQL"],
		},
		{
			title: "Space Project",
			screenshot: "./space_project.png",
			link: "",
			description:
				"A game developed in C# using Unity. The goal is to destroy the enemy ships, until you reach the boss.",
			tech: ["Unity", "C#"],
		},
		{
			title: "DeliVeRama",
			screenshot: "./deliverama.jpeg",
			link: "",
			description:
				"A virtual reality app in which the goal is to deliver packages",
			tech: ["Unity", "C#", "Oculus Rift"],
		},
	];

	const [popup, setPopup] = useState(null);
	const popupRef = useRef();

	const handleClick = (e, project) => {
		e.preventDefault();
		setPopup(project);
	};

	const handleClickOutside = (e) => {
		if (popupRef.current && !popupRef.current.contains(e.target)) {
			setPopup(null);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<section className="projects">
			<h1>Projects</h1>
			<div className="grid">
				{projects.map((project, index) => (
					<a
						href={project.link}
						target="_blank"
						rel="noopener noreferrer"
						key={index}
						className="project-card"
						onClick={(e) => handleClick(e, project)}
					>
						<img src={project.screenshot} alt={project.title} />
						<div className="overlay">
							<h3>{project.title}</h3>
							{/* Add the project tech stack */}
							<br />
							<ul>
								{project.tech.map((tech, index) => (
									<li key={index}>{tech}</li>
								))}
							</ul>
						</div>
					</a>
				))}
			</div>
			{popup && <div className="overlay__popup"></div>}
			{popup && (
				<div className="popup" ref={popupRef}>
					<img
						src={popup.screenshot}
						alt={popup.title}
						className="popup__image"
					/>

					<h2>
						{popup.title}
						{popup.link && (
							<a
								href={popup.link}
								target="_blank"
								rel="noopener noreferrer"
								style={{ marginLeft: "10px" }}
							>
								<FontAwesomeIcon icon={faExternalLinkAlt} />
							</a>
						)}
					</h2>
					<p>{popup.description}</p>
					<ul>
						{popup.tech.map((tech, index) => (
							<li key={index}>{tech}</li>
						))}
					</ul>
				</div>
			)}
		</section>
	);
};

export default Projects;
