import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faLinkedin,
	faTwitter,
	faInstagram,
	faGithub,
} from "@fortawesome/free-brands-svg-icons";
import {
	faCode,
	faShoppingCart,
	faGlobeAmericas,
} from "@fortawesome/free-solid-svg-icons";

const About = () => {
	return (
		<section className="about">
			<h1>About Us</h1>
			<div className="about__content">
				<p>
					Developing innovative solutions for 5 years and counting. <br />
					Proven track record in delivering high-quality software solutions.
				</p>
				<p>
					{/* <a
						className="social-button linkedin-button"
						href="https://www.linkedin.com/company/chencreations"
						target="_blank"
						rel="noopener noreferrer"
						aria-label="Connect with me on LinkedIn"
					>
						<FontAwesomeIcon icon={faLinkedin} />
					</a> */}
					{/* <a
						className="social-button github-button"
						href="https://github.com/florian-ct"
						target="_blank"
						rel="noopener noreferrer"
						aria-label="Follow me on GitHub"
					>
						<FontAwesomeIcon icon={faGithub} />
					</a> */}
					{/* <a
						className="social-button twitter-button"
						href="https://twitter.com/florianct"
						target="_blank"
						rel="noopener noreferrer"
						aria-label="Follow me on Twitter"
					>
						<FontAwesomeIcon icon={faTwitter} />
					</a> */}
					{/* <a
						className="social-button instagram-button"
						href="https://www.instagram.com/your-instagram-handle"
						target="_blank"
						rel="noopener noreferrer"
						aria-label="Follow me on Instagram"
					>
						<FontAwesomeIcon icon={faInstagram} />
					</a> */}
				</p>

				<div className="timeline">
					<div className="experience">
						<h3>2019 - 2020</h3>
						<p>
							Full Stack Developer <br /> Thetys-France
						</p>
					</div>
					<div className="experience">
						<h3>2020 - 2021</h3>
						<p>
							Full Stack Developer <br /> Fleepit
						</p>
					</div>
					<div className="experience">
						<h3>2021 - 2024</h3>
						<p>
							Software Engineer <br /> ArianeGroup
						</p>
					</div>
					<div className="experience">
						<h3>2023 - Present</h3>
						<p>
							Software Engineer <br /> Freelance
						</p>
					</div>
				</div>

				<p>Ready to make an impact on your next project ? Let's connect!</p>
			</div>
		</section>
	);
};

export default About;
