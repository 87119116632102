import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";

const Hero = () => {
	const [name, setName] = useState(""); // State for your name

	// Simulate fetching your name from an API or database
	useEffect(() => {
		setTimeout(() => {
			setName("ChenCreations");
		}, 1000);
	}, []);

	return (
		<div className="hero">
			<h1 className="hero-title">{name}</h1>
			<h2 className="hero-subtitle">Building solutions for you.</h2>
			<img
				src="terminal_screen.png"
				alt="Description of the image"
				class="hero-image"
			/>
			<img
				src="terminal_screen_mobile.png"
				alt=""
				className="hero-image-mobile"
			/>
			{/* <Link
				to="contact-section"
				smooth={true}
				duration={500}
				className="scroll-down classic-link"
			>
				Build
			</Link> */}
		</div>
	);
};

export default Hero;
